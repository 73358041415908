const backtotop = document.querySelector('.back-to-top');
const header = document.querySelector('.header');
function updateBacktotop(){
    const rect = header.getBoundingClientRect();
    const pos = rect.bottom + 100;
    let opacity = 1 - Math.max(0, Math.min(1, pos / 200));
    backtotop.style.opacity = opacity;
}

backtotop.querySelector('a').addEventListener('click', e => {
    e.preventDefault();
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
});

window.addAnimation(updateBacktotop);