const svgNS = 'http://www.w3.org/2000/svg';

function isTouchDevice() {
    return window.matchMedia("(pointer: coarse)").matches;
}

const mouse = {
    x: 0,
    y: 0
};
const cursor = document.createElement('div');
cursor.classList.add('cursor');
document.body.appendChild(cursor);

const svg = document.createElementNS(svgNS, 'svg');
svg.setAttribute('width', '48');
svg.setAttribute('height', '48');
svg.setAttribute('viewBox', '0 0 48 48');
cursor.appendChild(svg);

const bgCircle = document.createElementNS(svgNS, 'circle');
bgCircle.setAttribute('cx', '24');
bgCircle.setAttribute('cy', '24');
bgCircle.setAttribute('r', '16');
bgCircle.classList.add('bg');
svg.appendChild(bgCircle);

const borderCircle = document.createElementNS(svgNS, 'circle');
borderCircle.setAttribute('cx', '24');
borderCircle.setAttribute('cy', '24');
borderCircle.setAttribute('r', '16');
borderCircle.classList.add('border');
svg.appendChild(borderCircle);

const progressCircle = document.createElementNS(svgNS, 'circle');
progressCircle.setAttribute('cx', '24');
progressCircle.setAttribute('cy', '24');
progressCircle.setAttribute('r', '16');
progressCircle.classList.add('progress');
svg.appendChild(progressCircle);

function updateCursorPosition(e){
    if(isTouchDevice()) return;
    if(e instanceof MouseEvent){
        mouse.x = e.clientX;
        mouse.y = e.clientY;
    }
    cursor.style.left = `${mouse.x}px`;
    cursor.style.top = `${mouse.y}px`;
    cursor.classList.remove('hover');

    let hover = false;
    if(e.target !== document){
        let node = e.target;
        while(node){
            if(
                node.tagName &&
                (
                    node.tagName.toLowerCase() === 'a' ||
                    node.tagName.toLowerCase() === 'button' ||
                    node.classList.contains('has-hover')
                )
            ){
                hover = true;
                break;
            }
            node = node.parentNode;
        }

        cursor.classList.toggle('hover', hover);
    }
}

function updateCursorProgress(){
    document.body.classList.toggle('is-touch', isTouchDevice());
    if(isTouchDevice()) return;

    const c = 2 * Math.PI * 16;
    const scroll = window.scrollY;
    const height = document.body.scrollHeight - window.innerHeight;
    const progress = scroll / height;
    progressCircle.setAttribute('stroke-dashoffset', c - (progress * c));
}

document.addEventListener('mousemove', updateCursorPosition, false);
document.addEventListener('mouseenter', updateCursorPosition, false);
document.addEventListener('mouseover', updateCursorPosition, false);

window.addAnimation(updateCursorProgress);