let navTimer;
const mainNav = document.querySelector('.main-nav');
const headerNavButton = document.querySelector('.header-nav-button a');
headerNavButton.addEventListener('click', e => {
    e.preventDefault();

    updateMainNavCenter();
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });

    document.body.classList.toggle('nav-open');
    if(!document.body.classList.contains('nav-open')){
        document.body.classList.remove('nav-opened');
    }

    clearTimeout(navTimer);
    navTimer = setTimeout(() => {
        if(document.body.classList.contains('nav-open')){
            document.body.classList.add('nav-opened');
        }
    }, 1500);
});

function updateMainNavCenter(){
    const bbox = headerNavButton.getBoundingClientRect();
    mainNav.style.setProperty('--left', (bbox.left + bbox.width / 2) + 'px');
    mainNav.style.setProperty('--top',  (bbox.top + bbox.height / 2) + 'px');
}
updateMainNavCenter();