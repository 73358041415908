const animations = [];

window.addAnimation = func => {
    animations.push(func);
}

let prevDt = 0;
function animate(dt){
    requestAnimationFrame(animate);

    if(prevDt === 0){
        prevDt = dt;
        return;
    }
    const ft = dt - prevDt;
    for(const anim of animations) anim(dt, ft);
    prevDt = dt;
}

requestAnimationFrame(animate);