document.addEventListener('click', e => {
    if (!e.target.closest)
    {
        return;
    }

    let title = e.target.closest('.section-diensten-entry-title');
    if (title)
    {
        e.preventDefault();
        const currenEntry = title.closest('.section-diensten-entry');
        const entries = currenEntry.closest('.section-diensten-entries').querySelectorAll('.section-diensten-entry');
        for(const entry of entries){
            entry.classList[currenEntry !== entry ? 'remove' : 'toggle']('open');
        }

    }
});

function resizeDienstenEntries(){
    const entries = document.querySelectorAll('.section-diensten-entry');
    if(!entries || !entries.length) return;
    for(const entry of entries)
    {
        const description = entry.querySelector('.section-diensten-entry-description');
        description.style.height = 'auto';
        setTimeout(() => {
            description.style.height = description.scrollHeight + 'px';
        });
    }
}

document.addEventListener('dondr-load', resizeDienstenEntries);
window.addEventListener('resize', resizeDienstenEntries);