const gap = 25;

window.addAnimation((dt, ft) => {
    const slider = document.querySelector('.section-clients-slider-inner');
    const slides = document.querySelectorAll('.section-clients-client');
    if(!slider || !slides || !slides.length) return;
    let x = parseFloat(slider.dataset.x ?? 0);
    x -= 10 / ft;

    for(const slide of slides){
        const bbox = slide.getBoundingClientRect();
        if(bbox.right <= 0){
            slider.appendChild(slide);
            x += bbox.width + gap;
            break;
        }
    }

    slider.dataset.x = x.toFixed(2);
    slider.style.transform = `translateX(${x.toFixed(2)}px)`;
});