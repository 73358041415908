document.addEventListener('click', e => {
    if (!e.target.closest)
    {
        return;
    }

    let nav = e.target.closest('.section-tws-nav');
    if (nav)
    {
        e.preventDefault();

        let direction = nav.classList.contains('section-tws-prev') ? -1 : 1;
        let slider = nav.closest('.section-tws-slider');
        let inner = slider.querySelector('.section-tws-slider-inner');
        inner.scrollBy({
            top: 0,
            left: direction * 650,
            behavior: 'smooth'
        });
    }
})