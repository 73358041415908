document.addEventListener('DOMContentLoaded', async function() {
    let elements = document.querySelectorAll('[data-mockup]');
    if (elements.length > 0)
    {
        let THREE = await import('three');
        let { FBXLoader } = await import('three/addons/loaders/FBXLoader.js');
        let { default: modelUrl } = await import('../models/phone.fbx');
        
        let isInit = false, container;
        let camera, scene, renderer, model, dirLight;

        const clock = new THREE.Clock();

        let texture, scroll = 1;
        let textureMaterial = new THREE.MeshStandardMaterial({ 
            color: 0xffffff,
            emmisive: 0xffffff,
            emmisiveIntensity: 0.9,
            roughness: 0.3,
            metalness: 0
        });

        function init() {
            camera = new THREE.PerspectiveCamera( 45, container.offsetWidth / container.offsetHeight, 1, 10000 );
            camera.position.set( -1000, 1000, 2000 );
            camera.lookAt( 0, 0, 0 );

            scene = new THREE.Scene();

            const hemiLight = new THREE.HemisphereLight( 0xffffff, 0x444444, 1.5 * Math.PI );
            hemiLight.position.set( 5, 200, 10 );
            scene.add( hemiLight );

            dirLight = new THREE.DirectionalLight( 0xffffff, 0.25 * Math.PI );
            dirLight.position.set( 10, -10, 50 );
            scene.add( dirLight );

            // model
            const loader = new FBXLoader();
            loader.load( modelUrl, function ( object ) {
                object.position.set(0, 0, 0);
                object.rotateX(Math.PI / 2);
                scene.add(object);
                model = object;

                object.children[0].material = [
                    new THREE.MeshStandardMaterial({ 
                        color: 0x717378,
                        roughness: 0.5,
                        metalness: 0.25
                    }),
                    new THREE.MeshStandardMaterial({ 
                        color: 0x000000,
                        roughness: 0.2,
                        metalness: 0
                    }),
                    textureMaterial,
                ];
                object.children[1].material = object.children[2].material = new THREE.MeshStandardMaterial({ 
                    color: 0x717378,
                    roughness: 0.75,
                    metalness: 0.25
                });
            });

            renderer = new THREE.WebGLRenderer( {
                antialias: true,
                alpha: true
            } );
            renderer.outputColorSpace = THREE.LinearSRGBColorSpace;
            renderer.setPixelRatio( window.devicePixelRatio );
            renderer.setSize( container.offsetWidth, container.offsetHeight );
            container.appendChild( renderer.domElement );

            window.addEventListener( 'resize', onWindowResize );
            window.addEventListener( 'scroll', onScroll );

            isInit = true;
        }

        function onWindowResize() {

            camera.aspect = container.offsetWidth / container.offsetHeight;
            camera.updateProjectionMatrix();

            renderer.setSize( container.offsetWidth, container.offsetHeight );

        }

        function onScroll() {
            const scrollY = window.scrollY;
            const bbox = container.getBoundingClientRect();
            const amount = scrollY / (bbox.top + bbox.height);

            dirLight.position.set( 10, 10 - (amount * 10), 50 );
        }

        //

        function animate() {
            requestAnimationFrame( animate );
            const delta = clock.getDelta();
            const now = performance.now();

            if(model){
                model.rotation.set(
                    (Math.cos(now / 1000) / 16) + (Math.PI / 2),
                    Math.cos(now / 1250) / 32,
                    (Math.cos(now / 1500) / 16) 
                );
            }

            renderer.render( scene, camera );
        }

        for (let _container of elements) {
            container = _container;
            url = container.dataset.mockup;

            texture = new THREE.TextureLoader().load(url, function ( texture ) {
                let image = texture.source.data;
                texture.wrapS = THREE.RepeatWrapping;
                texture.wrapT = THREE.RepeatWrapping;
                texture.repeat.set(1, 1432 / image.height);
                texture.offset.set(0, (1 - (1432 / image.height)));
                texture.needsUpdate = true;

                textureMaterial.map = texture;
                textureMaterial.needsUpdate = true;

                if(!isInit){
                    init();
                    animate();
                }
        
                container.appendChild( renderer.domElement );
            });
        }
    }
});
