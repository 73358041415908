import './app.less';
import './script/animation.js';
import './script/backtotop.js';
import './script/cases.js';
import './script/people.js';
import './script/slider.js';
import './script/clients.js';
import './script/services.js';
import './script/home.js';
import './script/renderer.js';

import './script/headernav.js';
// import './script/socialsnav.js';
import './script/cursor.js';

// import './script/benchmark.js';

let prevHref = window.location.href;
let pagePosition = {};

// Test for webp support
const webP = new Image();
webP.src = 'data:image/webp;base64,UklGRjoAAABXRUJQVlA4IC4AAACyAgCdASoCAAIALmk0mk0iIiIiIgBoSygABc6WWgAA/veff/0PP8bA//LwYAAA';
webP.onload = webP.onerror = () => {
    let supported = webP.height === 2;
    document.body.classList.toggle('webp', supported);
    document.body.classList.toggle('no-webp', !supported);
};

window.addAnimation((delta, frame) => {
    document.querySelectorAll('.dondr-page-layout:not(.static-page) .translate-into-view').forEach(elem => effectTranslateIntoView(elem, frame));
    document.querySelectorAll('.dondr-page-layout:not(.static-page) .fade-into-view').forEach(elem => effectFadeIntoView(elem, frame));
    document.querySelectorAll('.fit-text').forEach(elem => fitText(elem, frame));
    document.body.classList.toggle('header-scrolled', 128 < scrollY);
});

function fitText(elem, frame){
    const elemWidth = elem.offsetWidth;
    const span = elem.querySelector('span');
    const spanWidth = span.offsetWidth;
    span.style.transform = spanWidth > elemWidth ? `scale(${elemWidth / spanWidth})` : '';
}

function effectTranslateIntoView(elem, frame){
    const rect = elem.parentNode.getBoundingClientRect();
    const top = rect.top + window.scrollY;
    const windowCenter = window.scrollY + (window.innerHeight / 2);
    const elemCenter = top + (rect.height / 2);
    const diffCenter = windowCenter - elemCenter;

    let translateY = (diffCenter * diffCenter) / Math.min(1000, (1920 - (window.innerWidth / 2)));
    if(diffCenter > 0) translateY *= -1;

    translateY = Math.max(-window.innerHeight / 2, Math.min(window.innerHeight / 2, translateY));

    const currentTranslateY = parseFloat(elem.dataset.translateY) || 0;
    const diffTranslateY = translateY - currentTranslateY;
    const easedTranslateY = currentTranslateY + (diffTranslateY / 10);
    let roundedEasedTranslateY = Math.min(window.innerHeight / 2, Math.round(easedTranslateY * 100) / 100);
    if(
        elem.classList.contains('static') ||
        (elem.parentNode && elem.parentNode.classList.contains('static'))
    ) roundedEasedTranslateY = Math.min(window.innerHeight / 2, translateY); 

    const opacity = Math.max(0, Math.min(1, 1 - (-0.5 + (Math.abs(diffCenter) / (window.innerHeight / 2)))));

    if(!elem.classList.contains('no-fade')){
        elem.style.opacity = opacity * 1.1;
    }
    elem.style.transform = `translate3d(0, ${roundedEasedTranslateY}px, 0)`;
    elem.dataset.translateY = roundedEasedTranslateY.toFixed(2);
}

function effectFadeIntoView(elem, frame){
    const offset = elem.dataset.offset ? (parseInt(elem.dataset.offset) * 50) : 0;
    const rect = elem.getBoundingClientRect();
    const top = rect.top + window.scrollY;
    const scrollY = window.scrollY + offset;
    if(top > window.scrollY + window.innerHeight || top + rect.height < scrollY) return;

    let opacity = Math.max(0, Math.min(1, 1 - (-1 + (Math.abs(top - scrollY) / (window.innerHeight / 1.75)))));

    const closeToBottom = document.body.offsetHeight - (window.innerHeight + Math.floor(scrollY));
    const footer = document.querySelector('.footer');
    const footerRect = footer.getBoundingClientRect();
    const closeVal = footerRect.height / 2;
    if(closeToBottom < closeVal){
        opacity += (closeVal - closeToBottom) / closeVal;
    }
    opacity = Math.min(1, opacity);
    elem.style.opacity = opacity * 1.1;

    const translateY = (1 - opacity) * 125;
    elem.style.transform = `translate3d(0, ${translateY}px, 0)`;
}

function traverseTarget(e){
    if (e.target === document || !e.target.closest || !e.target.closest('.dondr-page-layout'))
    {
        return;
    }

    let node = e.target;
    while(node){
        if(
            node.tagName &&
            node.tagName.toLowerCase() === 'a'
        ) break;
        
        node = node.parentNode;
    }

    if(!node || node === document) return;

    const href = node.getAttribute('href');
    if(!href || href == window.location.href) return;

    const isLocal = (
        href.indexOf(window.location.origin) === 0 && 
        href.indexOf('#') === -1
    ) || href.startsWith('/');
    
    if(!isLocal) return;

    e.preventDefault();
    loadPage(href);
}

const loadEvent = new Event('dondr-load');
document.dispatchEvent(loadEvent);

function loadPage(href, pushState = true){
    document.body.classList.add('page-busy');
    fetch(href, {
        method: 'GET',
        redirect: 'follow',
        headers: {
            'X-Requested-With': 'XMLHttpRequest',
            'Dondr-xhttp-request': 'true'
        },
    }).then(res => {
        if(res.status === 200){
            return res.text();
        }
        throw new Error('Not found');
    }).then(html => {
        const parser = new DOMParser();
        const doc = parser.parseFromString(html, 'text/html');
        const newBody = doc.querySelector('body');
        const newMain = newBody.querySelector('.main');
        const newTitle = doc.querySelector('title');
        const newNav = doc.querySelector('.main-nav-nav');

        pagePosition[window.location.href] = window.scrollY;

        document.title = newTitle.textContent;
        document.querySelector('.main').innerHTML = newMain.innerHTML;

        let url = new URL(href, window.location.origin);
        let parentHref = prevHref.split('/').slice(0, -1).join('/');
        let scrollTop = parentHref === url.href && pagePosition[url.href] ? pagePosition[url.href] : 0;

        if(pushState) history.pushState({}, '', url.href);
        prevHref = url.href;

        document.body.classList.remove('nav-open');
        document.body.classList.remove('nav-opened');

        const layout = document.querySelector('.dondr-page-layout');
        layout.classList.remove('static-page');
        if(doc.querySelector('.dondr-page-layout').classList.contains('static-page')){
            layout.classList.add('static-page');
        }

        setTimeout(() => {
            document.querySelector('.main-nav-nav').innerHTML = newNav.innerHTML;
            document.dispatchEvent(loadEvent);

            window.document.dispatchEvent(new Event('DOMContentLoaded', {
                bubbles: true,
                cancelable: true
            }));

            setTimeout(() => {
                window.scrollTo({
                    top: scrollTop,
                    behavior: 'instant',
                });
            }, 5);
            setTimeout(() => {
                document.body.classList.remove('page-busy');
            }, 250);

        }, 250);
    }).catch(err => {
        console.error(err);
    });
}

document.addEventListener('click', traverseTarget);

window.addEventListener('popstate', e => {
    loadPage(window.location.href, false);
});
