function updatePeople() {
    let entries = document.querySelectorAll('.section-people-inner');
    if(!entries || !entries.length) return;

    for(let entry of entries){
        let prev = entry.querySelector('.section-people-prev');
        prev.classList.toggle('visible', entry.scrollLeft > 0);

        let next = entry.querySelector('.section-people-next');
        next.classList.toggle('visible', entry.scrollLeft + entry.clientWidth < entry.scrollWidth);
    }
}

document.addEventListener('click', e => {
    if (!e.target.closest)
    {
        return;
    }

    let nav = e.target.closest('.section-people-nav');
    if (nav)
    {
        e.preventDefault();

        let direction = nav.classList.contains('section-people-prev') ? -1 : 1;
        let slider = nav.closest('.section-people-inner');
        let person = slider.querySelector('.section-people-person');
        slider.scrollBy({
            top: 0,
            left: direction * person.clientWidth,
            behavior: 'smooth'
        });
    }
})

window.addAnimation(updatePeople);
document.addEventListener('DOMContentLoaded', initPeopleScrollMobile);
document.addEventListener('resize', checkPeopleScrollMobile);

let peopleScrollEnabled = false;
let poepleScrollPeople = null;
let poepleScrollInner = null;
let poepleScrollWrapper = null;

function initPeopleScrollMobile()
{
    peopleScrollPeople = document.querySelector('.section-people');
    if (peopleScrollPeople)
    {
        peopleScrollInner = peopleScrollPeople.querySelector('.section-people-inner');
        peopleScrollWrapper = peopleScrollPeople.querySelector('.section-people-inner-wrapper');
    } else {
        peopleScrollInner = null;
        peopleScrollWrapper = null;
    }
    checkPeopleScrollMobile();
}

function checkPeopleScrollMobile()
{
    let clientWidth = document.documentElement.clientWidth;
    peopleScrollEnabled = peopleScrollPeople !== null && clientWidth <= 768;

    if (peopleScrollEnabled)
    {
        peopleScrollInner.style.overflow = 'hidden';
        peopleScrollWrapper.style.position = 'absolute';
        peopleScrollPeople.style.position = 'relative';    
        peopleScrollPeople.style.paddingBottom = peopleScrollInner.scrollWidth + 100 + 'px';
    }
    else
    {
        if(peopleScrollInner){
            peopleScrollInner.style.overflow = '';
        }
        if(peopleScrollWrapper){
            peopleScrollWrapper.style.position = '';
        }
        if(peopleScrollPeople){
            peopleScrollPeople.style.position = '';
            peopleScrollPeople.style.paddingBottom = '';
        }
    }
}

window.addAnimation(() => {
    if (peopleScrollEnabled)
    {
        let scroll = Math.max(0, window.scrollY - peopleScrollPeople.offsetTop + 200);
        peopleScrollInner.scrollLeft = scroll;
        if (scroll > 0)
        {
            let max = peopleScrollInner.scrollWidth - 350;
            if (scroll > max)
            {
                peopleScrollWrapper.style.position = 'absolute';
                peopleScrollWrapper.style.top = max + 'px';
            }
            else
            {
                peopleScrollWrapper.style.position = 'fixed';
                peopleScrollWrapper.style.top = '200px';
            }
        }
        else
        {
            peopleScrollWrapper.style.position = '';
            peopleScrollWrapper.style.top = '';
        }

    }
});