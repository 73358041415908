let timers = [];

document.addEventListener('click', e => {
    if (!e.target.closest)
    {
        return;
    }

    let casesFilter = e.target.closest('.section-cases-filter');
    if (casesFilter)
    {
        e.preventDefault();

        let filters = e.target.closest('.section-cases-filters');

        let cases = e.target.closest('.section-cases');

        let inner = cases.querySelector('.section-cases-entries');
        let cta = cases.querySelector('.section-cases-cta');
        if (cta)
        {
            cta = cta.closest('.section-cases-entry');
        }
        let entries = cases.querySelectorAll('.section-cases-entry');

        for (let filter of filters.querySelectorAll('.section-cases-filter'))
        {
            filter.classList.remove('section-cases-filter-current');
        }
        casesFilter.classList.add('section-cases-filter-current');
        let visible = [];
        let hidden = [];

        for(let timer of timers)
        {
            clearTimeout(timer);
        }
        timers = [];
        document.body.classList.add('case-busy');

        for (let entry of entries)
        {
            entry.classList.add('hidden');
            if (casesFilter.dataset.category == '' || typeof entry.dataset.categories == 'undefined' || entry.dataset.categories.split(',').indexOf(casesFilter.dataset.category) > -1)
            {
                // show entry
                if (entry !== cta)
                {
                    visible.push(entry);
                }
            }
            else
            {
                // hide entry
                hidden.push(entry);
            }

            timers.push(setTimeout(() => {
                entry.style.display = 'none';
                entry.classList.add('static');
            }, 500));
        }

        timers.push(setTimeout(() => {
            visible.sort((a, b) => parseInt(a.dataset.order) - parseInt(b.dataset.order));
            if (cta)
            {
                visible.splice(visible.length > 1 ? 3 : 1, 0, cta);
            }

            for (let entry of visible)
            {
                inner.appendChild(entry);
                entry.style.display = '';
                entry.classList.remove('static');
            }
            for (let entry of hidden)
            {
                inner.appendChild(entry);
            }
            timers.push(setTimeout(() => {
                for (let entry of visible)
                {
                    entry.classList.remove('hidden');
                }
                document.body.classList.remove('case-busy');
            }, 500));

        }, 500));

        // Scroll to inner
        if (window.scrollY < inner.offsetTop)
        {
            window.scrollTo({
                'behavior': 'smooth',
                'top': inner.offsetTop,
                'left': 0
            });
        }
    }
});

window.addAnimation(() => {
    let filters = document.querySelector('.section-cases-filters');
    if(!filters) return;
    let aspect = window.innerWidth / window.innerHeight;
    let factor = Math.max(2, Math.min(4, 6 - aspect));
    filters.style.transform = `translateY(${(window.scrollY / factor)}px)`;
});

function updateis() {
    for (let entry of document.querySelectorAll('.section-cases-entry'))
    {
        let titleSpans = entry.querySelectorAll('.section-cases-entry-title-word');
        let best = null;
        let best_x = null;
        let best_y = null;

        for (let span of titleSpans)
        {
            let brect = span.getBoundingClientRect();

            let i = span.querySelector('i');
            i.style.display = 'none';

            let x = brect.x + brect.width;

            if (best === null) {
                best = i;
                best_x = x;
                best_y = brect.y;
            } else if (best_x < x && brect.y <= best_y) {
                best = i;
                best_x = x;
                best_y = brect.y;
            }
        }

        if (best !== null) {
            best.style.display = '';
        }
    }
}

document.addEventListener('DOMContentLoaded', updateis);
window.addEventListener('resize', updateis);