document.addEventListener('click', e => {
    if (!e.target.closest)
    {
        return;
    }

    if (e.target.closest('.section-home-hero-button'))
    {
        e.preventDefault();

        let nextOffset = e.target.closest('.section-home-hero').nextElementSibling.offsetTop;
        window.scrollTo({
            top: nextOffset - 50,
            left: 0,
            behavior: 'smooth'
        });
    }
});